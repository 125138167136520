const handleConditionalInputs = async (inputs, conditionals) => {
    updateFormGroups(inputs, conditionals);

    inputs.forEach(input => {
        input.removeEventListener('change', () => updateFormGroups(inputs, conditionals));
        input.addEventListener('change', () => updateFormGroups(inputs, conditionals));
    });
}

/**
 * Updates form groups for the given inputs and conditionals to be visible or hidden
 *
 * @param {NodeListOf.<Element>} inputs - The inputs to show / hide conditionals
 * @param {Object.<string, Array.<string>} conditionals - Indexed using IDs, the conditionals to show if an input is checked
 */
const updateFormGroups = (inputs, conditionals) => {
    const checkedInput = Array.from(inputs).find(i => i.checked) || null;

    const [visibleFormGroups, hiddenFormGroups] = processConditionals(conditionals, checkedInput)

    showElements(visibleFormGroups)
    hideElements(hiddenFormGroups);
};

/**
 * Processes which conditionals should be displayed or hidden depending on the input
 * defaults to hiding all conditionals if no input is checked
 *
 * @param {Object.<string, Array.<string>} conditionals - Indexed using IDs, the conditionals to show if an input is checked
 * @param {Element | null} checkedInput - The input element that is checked
 * @returns Array.<Array.<Element|null>> Two arrays: the first with visible form groups, and the second with hidden form groups.
 */
const processConditionals = (conditionals, checkedInput) =>
    Object.entries(conditionals)
        .reduce((
        /** @type {Array.<Array.<Element|null>>} **/[hiddenElements, visibleElements],
            [inputId, selectors]) => {

            // Get the form groups of each of the selectors for this input
            const selectorsFormGroups = (strings) =>
                strings
                    .map(str => document.querySelector(str))
                    .map(findFormGroup)
                    .filter(Boolean)

            // Check that this input is checked and if so, show it's elements
            if (checkedInput && inputId === checkedInput.id) {
                return [
                    [...visibleElements, ...selectorsFormGroups(selectors)],
                    hiddenElements,
                ];
            };

            // Hide this input's conditionals
            return [
                visibleElements,
                [...hiddenElements, ...selectorsFormGroups(selectors)]
            ];
        }, [[], []])

/**
 * Finds the parent form group of a given node
 *
 * @param {Element} node - The node to find the form group of
 * @returns {Element | null} the form group element
 */
const findFormGroup = (node) => {
    while (node) {
        const currentNode = node;
        node = node.parentNode;

        if (!isElement(node)) continue;
        if (currentNode.classList.contains('form-group')) return currentNode;
    }

    return null;
};

/**
 * Checks if the given node is an element
 *
 * @param {Node} node - The node to check
 * @returns {boolean} If the node is an element
 */
const isElement = (node) => {
    return node.nodeType === 1;
};

/**
 * Adds the "hidden" class to all given elements
 *
 * @param {Array<Element>} elements - The elements to hide
 */
const hideElements = (elements) => elements.forEach(hideElement);

/**
 * Adds the "hidden" class to a given element
 *
 * @param {Element} element - The element to hide
 */
const hideElement = (element) => element.classList.add('hidden');

/**
 * Removes the "hidden" class from all given elements
 *
 * @param {Array<Element>} elements - The elements to show
 */
const showElements = (elements) => elements.forEach(showElement);

/**
 * Removes the "hidden" class from a given element
 *
 * @param {Element} element - The element to show
 */
const showElement = (element) => element.classList.remove('hidden');

export default handleConditionalInputs
